import { Box } from 'atomic-layout';
import styled from 'styled-components';

// TODO: green/red text
export const BTCValueCard = styled(Box)`
  display: flex;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  width: 100%;
  min-width: 25rem;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 0 2rem;
  background: ${({ theme }) => theme.colors.glassBackground};
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    flex: 1;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.lg.maxWidth}) {
    height: 100px;
  }
  min-height: 100px;
  height: 100%;
  box-sizing: border-box;
`;

export const CalculatorCard = styled(Box)`
  display: flex;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 1rem;
  background: ${({ theme }) => theme.colors.glassBackground};
  box-sizing: border-box;
`;

export const GraphCard = styled(Box)`
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  display: flex;
  flex-direction: column;
  align-items: center;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 1rem;
  background: ${({ theme }) => theme.colors.glassBackground};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .recharts-wrapper > svg.recharts-surface {
    margin-top: 15px;
  }
  @media screen and (max-width: ${({ theme }) =>
          theme.breakpoints.sm.maxWidth}) {
    .recharts-wrapper > svg.recharts-surface {
      margin-top: 25px;
    }
  }

`;

export const OutputCard = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: ${({ theme }) => theme.colors.shadow};
  max-height: 8rem;
  min-width: 20rem;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 1.5rem 1rem;
  background: ${({ theme }) => theme.colors.glassBackground};

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.lg.minWidth}) {
    margin: ${({ $margin }) => ($margin ? $margin : 'initial')};
  }
  box-sizing: border-box;
`;

export const LatestBlogCard = styled.a`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  position: relative;
  min-height: 100%;
  backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  -webkit-backdrop-filter: blur(${({ theme }) => theme.glassEffect.blur});
  border-radius: ${({ theme }) => theme.glassEffect.borderRadius};
  padding: 0;
  background: ${({ theme }) => theme.colors.glassBackground};
  overflow: hidden;

  text-decoration: none;

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.lg.minWidth}) {
    margin: ${({ $margin }) => ($margin ? $margin : 'initial')};
  }
  box-sizing: border-box;
`;

export const WarningCard = styled.div`
  background: ${({ theme }) => theme.colors.secondaryGradient};
  padding: 1rem;
  color: black;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;

  > p {
    margin: 0;
    font-weight: bold;
  }
`;

export const ImageBGCard = styled.div(
  ({ theme: { colors, glassEffect, breakpoints }, $img }) => ({
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: glassEffect.borderRadius,
    transition: 'box-shadow 0.56s ease-in-out',
    backgroundColor: colors.cardBackground,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundImage: `url(${$img})`,

    ['&:hover']: {
      cursor: 'pointer',
      boxShadow:
        '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
    },

    [`@media screen and (max-width: ${breakpoints.sm.maxWidth})`]: {
      minHeight: '20rem',
    },
  })
);

export const BlogCard = styled.div`
  box-shadow: ${({ theme }) => theme.colors.shadow};
  width: 100%;
  max-width: 33rem;
  min-width: 33rem;
  max-height: 45rem;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.glassBackground};
  border-radius: 5px;
  padding: 2rem;
  gap: 2rem;
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    max-width: 100%;
  }
`;
