import { Box, Composition } from 'atomic-layout';
import { H3, Paragraph } from 'components/atoms/Text';

import { OutputCard } from 'components/atoms/Cards';
import { colorFromValue } from 'utils/utils';
import styled from 'styled-components';
import { toCurrencyString } from 'utils/currency';
import { useCalculation } from 'components/content/calculator/calculationContext';

const StyledComposition = styled(Composition)`
  overflow-x: scroll;
  scrollbar-color: ${({ theme }) => theme.colors.scrollbar};

  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.minWidth}) {
    overflow-x: hidden;
    overflow: visible;
  }
`;

const StatsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  row-gap: 4rem;
  column-gap: 3rem;
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.sm.maxWidth}) {
    padding-bottom: 1rem;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.minWidth}) {
    width: initial;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.sm.minWidth}) and (max-width: ${({ theme }) =>
          theme.breakpoints.xl.minWidth}) {
    justify-content: space-between;
  }
  @media screen and (min-width: ${({ theme }) =>
      theme.breakpoints.xl.minWidth}) {
    justify-content: space-between;
  }
`;

const SingleStat = ({ label, value, colour }) => (
  <OutputCard minHeight="66px" $margin="0">
    <Paragraph $padding="initial" $lineHeight="initial">
      {label}
    </Paragraph>
    <H3 $color={colour}>{value}</H3>
  </OutputCard>
);

const CurrencyStat = ({
  label,
  value,
  currency,
  precision = undefined,
  ...options
}) => (
  <SingleStat
    label={label}
    value={
      value
        ? toCurrencyString(value, currency, {
            maximumFractionDigits: precision,
            ...options,
          })
        : ''
    }
  />
);

const statsArea = `
    stats
`;

const SingleStats = ({ singleStatsProps }) => {
  const { currency, singleStatData, isSatoshis } = useCalculation();
  const {
    totalValue = 0,
    totalInvested = 0,
    averageCryptoPrice = 0,
    investmentReturnPercentage = 0,
    purchasingPower = null
  } = singleStatData || {};

  const snipPercentage = (value) => {
    let calcVal = Number(value);
    if (calcVal > -0.01 && calcVal < 0) calcVal = 0;
    return calcVal;
  };

  const formattedPercentage = (value) => {
    return snipPercentage(value).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  };
  const investmentReturn = (investmentReturnPercent) => {
    if (!investmentReturnPercent || investmentReturnPercent === 0) return '';
    const value = formattedPercentage(investmentReturnPercent);
    return investmentReturnPercent > 0 ? `+${value}%` : `${value}%`;
  };

  return (
    <StyledComposition areas={statsArea} gap={2} height="100%">
      {({ Stats }) => (
        <>
          <Stats>
            <StatsContainer>
              <CurrencyStat
                label={singleStatsProps.totalInvestedLabel}
                value={totalInvested}
                currency={currency}
                precision={0}
              />
              <CurrencyStat
                label={singleStatsProps.totalValueLabel}
                value={totalValue}
                currency={currency}
                precision={0}
              />
              <SingleStat
                colour={colorFromValue(
                  snipPercentage(investmentReturnPercentage)
                )}
                label={singleStatsProps.investmentReturnLabel}
                value={investmentReturn(investmentReturnPercentage) || ''}
              />
              <CurrencyStat
                label={
                  isSatoshis
                    ? singleStatsProps.averageSatoshiPriceLabel
                    : singleStatsProps.averageBTCPriceLabel
                }
                value={averageCryptoPrice}
                currency={currency}
                precision={isSatoshis ? 8 : 0}
                maximumSignificantDigits={
                  averageCryptoPrice > 10 ? undefined : 3
                }
                minimumFractionDigits={0}
                maximumFractionDigits={averageCryptoPrice > 10 ? 0 : 8}
              />
              {purchasingPower !== null && <CurrencyStat
                  label={singleStatsProps.purchasingPowerLabel}
                  value={Math.round(purchasingPower).toString()}
                  currency={currency}
                  precision={0}
                  maximumSignificantDigits={undefined}
                  minimumFractionDigits={0}
                  maximumFractionDigits={2}
              />}
            </StatsContainer>
          </Stats>
        </>
      )}
    </StyledComposition>
  );
};

export default SingleStats;
